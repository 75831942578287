
export default {
  inheritAttrs: false,

  props: {
    message: {
      type: String,
      required: true,
    },

    colorName: {
      type: String,
      default: "pink",
    },
  },
};
